import { styled } from '@mui/material/styles';
import React from 'react';

const StyHighlighted = styled('span', {
    name: 'StyHighlighted',
})(({ theme }) => ({
    borderRadius: '0.2rem',
    margin: '0px 0.3rem',
    backgroundColor: theme.palette.primary.background,
}));

export const Highlighted = (props: {
    children: React.ReactNode;
}): React.ReactElement => <StyHighlighted>{props.children}</StyHighlighted>;
