import * as React from 'react';
import { useTranslation } from 'react-i18next';

import Popover from '@mui/material/Popover';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import InsertPhotoIcon from '@mui/icons-material/InsertPhoto';

import { Editor } from '@tiptap/core';
import EditorIcon from './EditorIcon';
import { convertFileToBase64 } from 'ui/TextEditor/helpers';

export default function ImagePicker({
    editor,
}: {
    editor: Editor;
}): React.ReactNode {
    const { t } = useTranslation();

    const [anchorEl, setAnchorEl] = React.useState(null);
    const close = () => setAnchorEl(null);

    const [url, setUrl] = React.useState<string>('');
    // @ts-expect-error [TS migration] Was not detected by flow
    const handleUrlChange = (event) => {
        setUrl(event.target.value);
    };

    const addImage = (imgSrc: string) => {
        if (imgSrc) {
            editor.chain().focus().setImage({ src: imgSrc }).run();
            close();
        }
    };

    // @ts-expect-error [TS migration] Was not detected by flow
    const handleFileChange = (event) => {
        const file = event.target.files && event.target.files[0];
        if (file) {
            convertFileToBase64(file).then(addImage);
        }
    };

    return (
        <>
            {/* @ts-expect-error [TS migration] Was not detected by flow */}
            <EditorIcon onClick={(event) => setAnchorEl(event.currentTarget)}>
                <InsertPhotoIcon />
            </EditorIcon>
            <Popover
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                transformOrigin={{ vertical: 'top', horizontal: 'center' }}
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={close}
                PaperProps={{ style: { padding: '1rem' } }}
            >
                <Typography variant="body2">
                    {t('paragraph.fromUrl')}
                </Typography>
                <TextField
                    variant="standard"
                    label="url"
                    size="small"
                    value={url}
                    onChange={handleUrlChange}
                />
                <Button
                    onClick={() => addImage(url)}
                    size="small"
                    variant="contained"
                    component="span"
                >
                    {t('paragraph.addImage')}
                </Button>

                <Typography variant="body2">
                    {t('paragraph.fromFile')}
                </Typography>
                <input
                    accept="image/*"
                    id="contained-button-file"
                    type="file"
                    style={{ display: 'none' }}
                    onChange={handleFileChange}
                />
                <label htmlFor="contained-button-file">
                    <Button size="small" variant="contained" component="span">
                        {t('paragraph.upload')}
                    </Button>
                </label>
            </Popover>
        </>
    );
}
