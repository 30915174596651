import React from 'react';

import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineDot from '@mui/lab/TimelineDot';
import Avatar from '@mui/material/Avatar';

import { stringToColor } from 'routes/BudgetModule/components/shared/InscriptionsGrid/InscriptionDetails/helpers/styleHelpers';
import { styled, useTheme } from '@mui/material/styles';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import TimelineContent from '@mui/lab/TimelineContent';
import { PanelDetailsEvent } from '../PanelDetails';

export type ItemProps = {
    event: PanelDetailsEvent;
    getEventComponent: (event: PanelDetailsEvent) => React.ReactElement;
};

const StyAvatar = styled(Avatar, {
    name: 'StyAvatar',
    shouldForwardProp: (prop) => prop !== 'backgroundColor',
})<{ backgroundColor: React.CSSProperties['backgroundColor'] }>(
    ({ backgroundColor }) => ({
        backgroundColor,
        width: '20px',
        height: '20px',
        fontSize: '15px',
    }),
);

const StyTimestamp = styled('p', {
    name: 'StyTimestamp',
})(({ theme }) => ({
    color: theme.palette.mediumGrey,
    marginBottom: 0,
}));

const formatDate = (date: string): string => {
    return new Date(date)
        .toLocaleString(undefined, {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
        })
        .replace(' ', ' à ');
};

function PanelDetailsHistoryItem({
    event,
    getEventComponent,
}: ItemProps): React.ReactElement {
    const theme = useTheme();
    const backgroundColor =
        event.user !== null
            ? stringToColor(event.user.email)
            : theme.palette.grey[500];

    return (
        <TimelineItem>
            <TimelineOppositeContent sx={{ padding: 0, flex: 0 }} />
            <TimelineSeparator>
                <TimelineDot sx={{ backgroundColor }}>
                    <StyAvatar backgroundColor={backgroundColor}>
                        {event.user?.email[0].toUpperCase() ?? ''}
                    </StyAvatar>
                </TimelineDot>
                {!event.lastItem && <TimelineConnector />}
            </TimelineSeparator>
            <TimelineContent sx={{ paddingRight: 0 }}>
                {getEventComponent(event)}
                <StyTimestamp>{formatDate(event.date)}</StyTimestamp>
            </TimelineContent>
        </TimelineItem>
    );
}

export default PanelDetailsHistoryItem;
