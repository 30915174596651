import { Box, styled } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';

import SeeMoreButton from './SeeMoreButton';

const StyChipsListContainer = styled(Box, {
    shouldForwardProp: (prop) =>
        prop !== 'showMoreChips' && prop !== 'defaultMaxHeight',
})<{ showMoreChips: boolean; defaultMaxHeight: string }>(
    ({ showMoreChips, defaultMaxHeight, theme }) => ({
        alignItems: 'center',
        display: 'flex',
        flexFlow: 'row wrap',
        justifyContent: 'flex-start',
        gap: theme.spacing(1),
        ...(showMoreChips ? {} : { maxHeight: defaultMaxHeight }),
        overflowY: showMoreChips ? 'auto' : 'hidden',
        width: '100%',
    }),
);

type AdaptiveChipListProps = {
    children: React.ReactNode;
    defaultMaxHeight: string;
    chipsListLength: number;
};

const AdaptiveChipList = ({
    defaultMaxHeight,
    children,
    chipsListLength,
}: AdaptiveChipListProps): JSX.Element => {
    const chipsContainerRef = useRef<HTMLDivElement>(null);
    const [showMoreChips, setShowMoreChips] = useState(false);
    const [areChipsOverflowing, setAreChipsOverflowing] = useState(false);
    useEffect(() => {
        const chipsContainer = chipsContainerRef.current;
        if (chipsContainer) {
            setAreChipsOverflowing(
                chipsContainer.scrollHeight > chipsContainer.clientHeight,
            );
        }
    }, [chipsListLength]);

    return (
        <>
            <StyChipsListContainer
                showMoreChips={showMoreChips}
                defaultMaxHeight={defaultMaxHeight}
                ref={chipsContainerRef}
            >
                {children}
            </StyChipsListContainer>
            {areChipsOverflowing && (
                <SeeMoreButton
                    showMore={showMoreChips}
                    onClick={() => setShowMoreChips(!showMoreChips)}
                />
            )}
        </>
    );
};

export default AdaptiveChipList;
