export const parseMentionsFrom = (content?: string): number[] => {
    if (!content) {
        return [];
    }
    const tempElement = document.createElement('div');
    tempElement.innerHTML = content;
    const mentionElements = tempElement.querySelectorAll(
        'span[data-type="mention"]',
    );

    const ids: number[] = [];
    for (let i = 0; i < mentionElements.length; i += 1) {
        const id = mentionElements[i].getAttribute('data-id');
        if (id) {
            ids.push(parseInt(id, 10));
        }
    }
    return ids;
};
