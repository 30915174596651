import * as React from 'react';
import Flex from 'ui/Flex/Flex';
import Popover from '@mui/material/Popover';
import FormatSizeIcon from '@mui/icons-material/FormatSize';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { styled } from '@mui/material/styles';
import { Editor } from '@tiptap/core';
import EditorIcon from './EditorIcon';

type FontSizeText = {
    text: string;
    fontSize: React.CSSProperties['fontSize'];
    fontWeight: React.CSSProperties['fontWeight'];
    lineHeight: React.CSSProperties['lineHeight'];
};

const FONT_SIZES: Record<string, FontSizeText> = {
    TITLE_1: {
        text: 'Titre 1',
        fontSize: '28px',
        fontWeight: 'bold',
        lineHeight: '42px',
    },
    TITLE_2: {
        text: 'Titre 2',
        fontSize: '21px',
        fontWeight: 'bold',
        lineHeight: '32px',
    },
    TEXT_BIG: {
        text: 'Texte (grand)',
        fontSize: '18px',
        fontWeight: 'normal',
        lineHeight: '26px',
    },
    TEXT_MEDIUM: {
        text: 'Texte (moyen)',
        fontSize: '16px',
        fontWeight: 'normal',
        lineHeight: '22px',
    },
    TEXT_SMALL: {
        text: 'Texte (petit)',
        fontSize: '14px',
        fontWeight: 'normal',
        lineHeight: '18px',
    },
};

const StyledFontSizeOption = styled('div', {
    name: 'StyledFontSizeOption',
    shouldForwardProp: (prop) => prop !== 'isActive',
})<{ isActive: boolean }>(({ theme, isActive }) => ({
    backgroundColor: isActive ? theme.palette.primary.background : 'inherit',
    padding: '1rem',
    cursor: 'pointer',
    transition: 'background-color 300ms ease',
    '&:hover': {
        backgroundColor: theme.palette.primary.background,
    },
}));

/**
 * Picker to select Font Size.
 *
 * @param editor
 */
export default function FontSizePicker({
    editor,
}: {
    editor: Editor;
}): React.ReactElement {
    const [anchorEl, setAnchorEl] = React.useState<Element | null>(null);
    const close = () => setAnchorEl(null);

    const handleFontSizeChange = (
        fontSize: React.CSSProperties['fontSize'],
        fontWeight: React.CSSProperties['fontWeight'],
        lineHeight: React.CSSProperties['lineHeight'],
    ) => {
        editor.chain().focus().setFontSize(fontSize).run();
        editor.chain().focus().setLineHeight(lineHeight).run();
        if (fontWeight === 'bold') {
            editor.chain().focus().setBold().run();
        } else {
            editor.chain().focus().unsetBold().run();
        }
    };

    const currentFontSize = editor.getAttributes('textStyle')?.fontSize;
    if (currentFontSize === undefined)
        handleFontSizeChange(
            FONT_SIZES.TEXT_SMALL.fontSize,
            FONT_SIZES.TEXT_SMALL.fontWeight,
            FONT_SIZES.TEXT_SMALL.lineHeight,
        );
    // marginLeft: '-8px' on ArrowDropDownIcon is set because of the scaled svg still taking the space.
    return (
        <>
            <EditorIcon
                key="fontSizes"
                onClick={(event: React.MouseEvent<Element>) => {
                    setAnchorEl(event.currentTarget);
                }}
                sx={{ '&:hover svg': { fill: '#06c' }, pr: 0 }}
            >
                <FormatSizeIcon />
                <ArrowDropDownIcon sx={{ marginLeft: '-8px' }} />
            </EditorIcon>
            <Popover
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={close}
            >
                <Flex flexDirection="column">
                    {Object.entries(FONT_SIZES).map(
                        ([key, { text, fontSize, fontWeight, lineHeight }]) => (
                            <StyledFontSizeOption
                                key={key}
                                isActive={editor.isActive('textStyle', {
                                    fontSize,
                                })}
                                sx={{ fontSize, fontWeight }}
                                onClick={() => {
                                    handleFontSizeChange(
                                        fontSize,
                                        fontWeight,
                                        lineHeight,
                                    );
                                    close();
                                }}
                            >
                                {text}
                            </StyledFontSizeOption>
                        ),
                    )}
                </Flex>
            </Popover>
        </>
    );
}
