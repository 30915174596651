import React from 'react';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

import { styled } from '@mui/material/styles';
import { Box } from '@mui/system';

type Props = {
    title: string;
    onClose: () => void;
};

const StyHeader = styled(Box, {
    name: 'StyHeader',
})({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
});

export default function PanelDetailsHeader({
    title,
    onClose,
}: Props): React.ReactElement {
    return (
        <StyHeader>
            <Typography variant="h4">{title}</Typography>
            <IconButton aria-label="delete" onClick={onClose} size="large">
                <CloseIcon />
            </IconButton>
        </StyHeader>
    );
}
