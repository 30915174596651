import React from 'react';
import AddIcon from '@mui/icons-material/Add';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

type Props = {
    text: string;
    onClick?: () => void;
    'data-testid'?: string;
};

const AddButton = ({
    text,
    onClick = () => {},
    'data-testid': dataTestId = '',
}: Props): React.ReactElement => (
    <Button variant="contained" onClick={onClick} data-testid={dataTestId}>
        <AddIcon fontSize="small" sx={{ marginRight: '0.5rem' }} />
        <Typography variant="body1SemiBold" color="inherit">
            {text}
        </Typography>
    </Button>
);

export default React.memo(AddButton);
