import { Typography, Box } from '@mui/material';
import React from 'react';

type Props = {
    title?: string;
    subtitle?: string;
    description?: string;
};

const ModalHeader = ({ title, subtitle, description }: Props) => (
    <Box>
        {title && (
            <Typography variant="h4" color="primary">
                {title}
            </Typography>
        )}
        {subtitle && (
            <Typography variant="h5" sx={{ mb: '0.8rem', mt: '2rem' }}>
                {subtitle}
            </Typography>
        )}
        {description && (
            <Typography
                variant="body1"
                sx={{
                    fontSize: '12px',
                    mb: '1rem',
                    color: 'rgba(0, 0, 0, 0.6)',
                }}
            >
                {description}
            </Typography>
        )}
    </Box>
);

export default ModalHeader;
