import React from 'react';
import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import LightbulbCircleIcon from '@mui/icons-material/LightbulbCircle';
import CloseIcon from '@mui/icons-material/Close';

const StyHelperBox = styled(Box, { name: 'HelperBox' })(({ theme }) => ({
    borderRadius: '8px',
    borderStyle: 'solid',
    borderWidth: '1px',
    borderColor: theme.palette.primary.main,
    backgroundColor: theme.palette.primary.background,
    color: theme.palette.primary.main,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '16px',
    gap: '16px',
    fontFamily: 'Poppins',
    fontSize: '14px',
    fontWeight: 600,
    fontStyle: 'normal',
    '& a': {
        color: theme.palette.primary.main,
        textDecorationLine: 'underline',
    },
}));

const StyGrower = styled('div', { name: 'StyGrower' })(() => ({
    flexGrow: 1,
}));

const HelperBox = ({
    children,
    className,
    closeable = false,
    onClose = undefined,
}: {
    children: React.ReactElement;
    className?: string;
    closeable?: boolean;
    onClose?: () => void;
}) => {
    return (
        <StyHelperBox className={className}>
            <LightbulbCircleIcon
                color="primary"
                sx={{
                    width: 24,
                    height: 24,
                    flexShrink: 0,
                    flexGrow: 0,
                }}
            />
            <StyGrower>{children}</StyGrower>
            {closeable && (
                <CloseIcon
                    color="primary"
                    onClick={onClose}
                    sx={{
                        width: 24,
                        height: 24,
                        flexShrink: 0,
                        flexGrow: 0,
                        cursor: 'pointer',
                    }}
                />
            )}
        </StyHelperBox>
    );
};

export default HelperBox;
