export const CHART_ACTION_TYPE = {
    Alert: 'alert',
    Delete: 'delete',
    Duplicate: 'duplicate',
    Edit: 'edit',
    Rename: 'rename',
    Resize: 'resize',
    Update: 'update',
    Insert: 'insert',
    InsertIndicator: 'insertIndicator',
    CopyAnchor: 'copyAnchor',
    InsertTitle: 'insertTitle',
    ViewHeadline: 'viewHeadline',
    Separator: 'separator',
    AddLink: 'addLink',
    InsertPageBreak: 'insertPageBreak',
    FormatBulletedList: 'formatBulletedList',
} as const;
