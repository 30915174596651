import React from 'react';
import Typography from '@mui/material/Typography';
import { Box } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import { ParentDetailsMapping } from '../PanelDetails';

type Props = {
    valuesToDisplay: ParentDetailsMapping[];
};

const StyValueBlock = styled(Box, {
    name: 'StyValueBlock',
})(({ theme }) => ({
    display: 'flex',
    marginTop: theme.spacing(1),
    gap: theme.spacing(1),
}));

export default function PanelDetailsParent({
    valuesToDisplay,
}: Props): React.ReactElement {
    const theme = useTheme();
    return (
        <Box
            sx={{
                mt: theme.spacing(3),
            }}
        >
            {valuesToDisplay.map((value) => (
                <StyValueBlock key={value.translation}>
                    <Typography
                        sx={{ alignSelf: 'center' }}
                        variant="body1Bold"
                    >
                        {value.translation}
                    </Typography>
                    <Typography>{value.value}</Typography>
                </StyValueBlock>
            ))}
        </Box>
    );
}
