import { Theme } from '@mui/material';

export const MentionStyle = (theme: Theme) => ({
    '& .mention': {
        color: theme.palette.primary.dark,
        backgroundColor: theme.palette.primary.background,
        borderRadius: '3px',
        padding: '2px 1px',
        cursor: 'pointer',
        '&:hover': {
            '& + .mention-popover': {
                visibility: 'visible',
                opacity: 1,
            },
        },
    },
    '.mention-popover': {
        transition:
            'opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0.5s, visibility 200ms 0.5s',
        visibility: 'hidden',
        opacity: 0,
        width: 'max-content',
        padding: '1rem',
        left: '50%',
        transform: 'translateX(-50%)',
        backgroundColor: 'white',
        borderRadius: '4px',
        position: 'absolute',
        zIndex: 10,
        boxShadow:
            'rgba(0, 0, 0, 0.2) 0px 5px 5px -3px, rgba(0, 0, 0, 0.14) 0px 8px 10px 1px, rgba(0, 0, 0, 0.12) 0px 3px 14px 2px',
        // We add this because we don't want the poppover to disappear while we hover it.
        '&:hover': {
            visibility: 'visible',
            opacity: 1,
        },
    },
    '.mention-popover > table': {
        whiteSpace: 'nowrap',
        tableLayout: 'auto',
        '& td': {
            border: 'transparent',
        },
        '& td + td': {
            fontWeight: 'bold',
        },
    },
});
