import * as React from 'react';
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';

/** Represent a Title with an optional children component, usually a button
 *  positioned on the right side of the title.
 */
export const LargeTitle = ({
    title,
    children,
    className = '',
}: {
    title: string;
    children?: React.ReactNode;
    className?: string;
}): React.ReactElement => (
    <Grid container direction="row" alignItems="center" className={className}>
        <Typography variant="h5">{title}</Typography>
        {children}
    </Grid>
);
