import Button from '@mui/material/Button';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import React from 'react';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';

type SeeMoreButtonProps = {
    showMore: boolean;
    onClick: () => void;
};

const StartIcon = (showMore: boolean) =>
    showMore ? (
        <KeyboardArrowUpIcon fontSize="small" />
    ) : (
        <KeyboardArrowDownIcon fontSize="small" />
    );

const StyButton = styled(Button)({
    alignSelf: 'center',
    fontSize: '0.7rem',
    '&.MuiButton-root:hover': {
        backgroundColor: 'transparent',
    },
    padding: 0,
});

const SeeMoreButton = ({
    showMore,
    onClick,
}: SeeMoreButtonProps): JSX.Element => {
    const { t } = useTranslation();
    return (
        <StyButton
            variant="text"
            startIcon={StartIcon(showMore)}
            onClick={onClick}
            size="small"
        >
            {t(`common.${showMore ? 'seeLess' : 'seeMore'}`)}
        </StyButton>
    );
};

export default SeeMoreButton;
