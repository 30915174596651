import Mention, { MentionOptions } from '@tiptap/extension-mention';
import { UserInfoT, UserT } from 'business/definitions';
import { getUserLabel } from 'helpers/users';
import { t } from 'i18next';

const userInfo = ['gender', 'firstname', 'lastname', 'job', 'email'] as const;
const userInfoTranslateValue = ['gender'];

const buildMentionWrapper = () => {
    const mentionWrapper = document.createElement('div');
    mentionWrapper.classList.add('mention-wrapper');
    mentionWrapper.style.display = 'inline-block';
    mentionWrapper.style.position = 'relative';
    return mentionWrapper;
};

const buildMentionElement = (userId: string) => {
    const mention = document.createElement('span');
    mention.classList.add('mention');
    mention.setAttribute('data-type', 'mention');
    mention.setAttribute('data-id', userId);
    mention.setAttribute('contenteditable', 'false');
    return mention;
};

const findMentionUser = (
    getFn: (query: string) => Array<UserT & UserInfoT>,
    userId: string | number,
) => {
    const users: Array<UserT & UserInfoT> = getFn('');
    const mentionedUser = users.find(
        (i) => i.id.toString() === userId.toString(),
    );
    return mentionedUser;
};

const buildMentionPopover = (mentionedUser: UserT & UserInfoT) => {
    // building a <div><table>...</table></div>
    const popover = document.createElement('div');
    popover.classList.add('mention-popover');
    popover.setAttribute('contenteditable', 'false');

    const table = document.createElement('table');
    userInfo.forEach((field) => {
        const row = table.insertRow();
        const labelCell = row.insertCell();
        const valueCell = row.insertCell();

        const translationKey = `mention.${field}`;
        labelCell.textContent = `${t(translationKey)} :`;
        if (userInfoTranslateValue.includes(field)) {
            const value = mentionedUser[field];
            valueCell.textContent = value ? t(`mention.${field}&${value}`) : '';
        } else {
            valueCell.textContent = mentionedUser[field] ?? '';
        }
    });

    popover.append(table);
    return popover;
};

const applyMentionPopoverOffset = (
    p: HTMLElement,
    w: HTMLElement,
    boundingBox: ExtendedMentionOptions['boundingBox'],
    margin: ExtendedMentionOptions['margin'],
    isParagraphWidget: boolean = false,
) => {
    const marginLocal = margin ?? 0;
    const boundingBoxLocal = boundingBox ?? {
        left: 0,
        right: 0,
    };

    // We add a special case here for paragraph mentions because the position absolute
    // couldn't fix the position of the poppover. We needed the position fixed.
    if (isParagraphWidget) {
        p.style.position = 'fixed';
        p.style.top = `${w.offsetTop + 40}px`;
        p.style.left = `${w.offsetLeft + 20}px`;
        p.style.transform = `translateX(0px)`;

        return;
    }

    p.style.bottom = '170%';

    // Initialize the offset to be the center of our popover.
    // This is the correct value if we only want to center the popover.
    let offset = -0.5 * p.offsetWidth;
    // Add a positive amount to offset when the popover is too far to the left
    // and would overflow from the bounding box.
    offset += Math.max(
        0,
        0.5 * (p.offsetWidth - w.offsetWidth) -
            w.offsetLeft -
            boundingBoxLocal.left +
            marginLocal,
    );
    // There's no right equivalent of element.offsetLeft, so we have to compute it ourselves.
    const mentionOffsetRight = w.offsetParent
        ? w.offsetParent.clientWidth - w.offsetWidth - w.offsetLeft
        : 0;

    // Add a negative amount to offset when the popover is too far to the right
    // and would overflow from the bounding box.
    offset -= Math.max(
        0,
        0.5 * (p.offsetWidth - w.offsetWidth) -
            mentionOffsetRight -
            boundingBoxLocal.right +
            marginLocal,
    );
    p.style.transform = `translateX(${offset}px)`;
};

type ExtendedMentionOptions = {
    /* Controls how far the popover can overflow the editor right and left. Default is { left: 0, right: 0 } */
    boundingBox?: { left: number; right: number };
    /* Controls how far from the bounding box the popover should be placed. */
    margin?: number;
    /* Indicates if the current text editor is in a widget component. */
    isParagraphWidget?: boolean;
};

let observer: MutationObserver;

// This is a customized behavior of the Mention extension.
// It allows use to add a popover when hovering over the mentions.
// This is done by changing the addNodeView() function
// and build the html we need with vanilla JS.
// Another pro for this approach is that the tag label and
// the information displayed in the popover are computed and
// not stored, which means that if the user infos change,
// the change will reflect on the mention.
// /!\ It has to be used with the style located in ./style.ts /!\
export default Mention.extend<MentionOptions & ExtendedMentionOptions>({
    onCreate() {
        // We're using this mutation observer as a way of executing a callback
        // when a change has been made in the editor. The callback fetches
        // all the mentions of the editor and computes the horizontal offset
        // to apply to their popovers so they do not overflow.
        observer = new MutationObserver(() => {
            const mentionWrappers =
                this.editor.view.dom.querySelectorAll<HTMLElement>(
                    'div.mention-wrapper',
                );
            mentionWrappers.forEach((w) => {
                const p = w.querySelector<HTMLElement>('div.mention-popover');
                if (p === null) return;
                applyMentionPopoverOffset(
                    p,
                    w,
                    this.options.boundingBox,
                    this.options.margin,
                    this.options.isParagraphWidget,
                );
            });
        });

        observer.observe(this.editor.view.dom, {
            attributes: false,
            childList: true,
            characterData: true,
            subtree: true,
        });
    },

    onDestroy() {
        observer?.disconnect();
    },

    addNodeView() {
        return ({ node, extension }) => {
            // Create the root element of the mention
            const mentionWrapper = buildMentionWrapper();

            const mention = buildMentionElement(node.attrs.id);
            mentionWrapper.append(mention);

            const mentionedUser = findMentionUser(
                (q) => extension.options.suggestion.items({ query: q }),
                node.attrs.id,
            );

            const label =
                mentionedUser !== undefined
                    ? getUserLabel(mentionedUser)
                    : t('common.unknown');

            // Build the text node of the mention
            const mentionText = document.createTextNode(
                `${extension.options.suggestion.char}${label}`,
            );
            mention.append(mentionText);

            if (mentionedUser !== undefined) {
                const popover = buildMentionPopover(mentionedUser);
                mentionWrapper.append(popover);

                // The other observer watches changes made to the editor.
                // This one waits for the popover to be mounting in the DOM
                // before applying the positioning. And it does so only once
                // and then disconnects.
                const firstTimeObserver = new MutationObserver(() => {
                    if (!this.editor.view.dom.contains(mentionWrapper)) {
                        return;
                    }

                    applyMentionPopoverOffset(
                        popover,
                        mentionWrapper,
                        extension.options.boundingBox,
                        extension.options.margin,
                        extension.options.isParagraphWidget,
                    );
                    firstTimeObserver.disconnect();
                });

                firstTimeObserver.observe(this.editor.view.dom, {
                    attributes: false,
                    childList: true,
                    characterData: true,
                    subtree: true,
                });
            }

            return {
                dom: mentionWrapper,
            };
        };
    },
});
