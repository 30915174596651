import { ColDef } from 'ag-grid-community';

export const AGGRID_DEFAULT_ROW_HEIGHT = 36;
export const AGGRID_DEFAULT_HEADER_HEIGHT = 36;

export const AGGRID_DEFAULT_COL_DEF: ColDef = {
    minWidth: 80,
    sortable: true,
    resizable: true,
    filter: 'agTextColumnFilter',
    floatingFilter: true,
};
