import Link from '@tiptap/extension-link';

/**
 * Custom Tiptap Link Extension
 *
 * Enhances the default Tiptap Link extension by ensuring that:
 * - Anchor links (`#some-id`) pointing to elements on the same page open in the same tab.
 * - External links or invalid anchor links open in a new tab (`target="_blank"`).
 */
const EnrichedLinkExtension = Link.extend({
    addAttributes() {
        return {
            ...this.parent?.(),
            target: {
                parseHTML: (element) => element.getAttribute('target'),
                renderHTML: (attributes) => {
                    if (!attributes.href) return {};

                    const currentPage =
                        window.location.origin + window.location.pathname;

                    try {
                        const linkUrl = new URL(
                            attributes.href,
                            window.location.href,
                        );

                        const isSamePageAnchor =
                            linkUrl.origin + linkUrl.pathname === currentPage &&
                            linkUrl.hash;

                        return { target: isSamePageAnchor ? null : '_blank' };
                    } catch {
                        return { target: '_blank' }; // Invalid URL, open in new tab
                    }
                },
            },
        };
    },
});

export default EnrichedLinkExtension;
