import * as React from 'react';

import FormatColorTextIcon from '@mui/icons-material/FormatColorText';
import Grid from '@mui/material/Unstable_Grid2';
import IconButton from '@mui/material/IconButton';
import Popover from '@mui/material/Popover';
import DotIcon from '@mui/icons-material/FiberManualRecord';

import {
    MANTY_BLACK,
    MANTY_COLOR_PALETTE,
    MANTY_MEDIUM_GREY,
} from 'styles/theme';
import { styled, useTheme } from '@mui/material';
import { Editor } from '@tiptap/core';

const StyIconButton = styled(IconButton, {
    name: 'ColorPickerStyIconButton',
    shouldForwardProp: (prop) => prop !== 'svgColor',
})<{ svgColor: string }>(({ svgColor }) => ({
    '&:hover': { backgroundColor: 'transparent' },
    '& svg': {
        color: svgColor,
        fill: svgColor,
    },
    '& svg:hover': {
        transform: 'scale(1.2)',
    },
}));

const colors = [...MANTY_COLOR_PALETTE.colors, MANTY_BLACK, MANTY_MEDIUM_GREY];

export default function ColorPicker({
    editor,
}: {
    editor: Editor;
}): React.ReactElement {
    const theme = useTheme();
    const [anchorEl, setAnchorEl] = React.useState<Element | null>(null);
    const close = () => setAnchorEl(null);

    const currentColor =
        editor.getAttributes('textStyle')?.color || MANTY_BLACK;

    return (
        <>
            <FormatColorTextIcon
                sx={{ fontSize: '1.8rem', scale: '0.8', fill: currentColor }}
                onClick={(event) => setAnchorEl(event.currentTarget)}
            />
            <Popover
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={close}
            >
                <Grid container sx={{ width: theme.spacing(26) }}>
                    {colors.map((color) => (
                        <Grid xs={2} key={`color-${color}`}>
                            <StyIconButton
                                svgColor={color}
                                onClick={() => {
                                    editor
                                        .chain()
                                        .focus()
                                        .setColor(color)
                                        .run();
                                    close();
                                }}
                            >
                                <DotIcon />
                            </StyIconButton>
                        </Grid>
                    ))}
                </Grid>
            </Popover>
        </>
    );
}
